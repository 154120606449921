<template>
  <div v-if="firstLoading" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex items-center justify-between">
        <div class="cursor-pointer text-white heading-3 pl-2 font-semibold"  @click="$router.push({name: 'SubscriptionStorage'})"><i class="fas fa-arrow-left"></i></div>
        <h1 class="heading-2 text-center">Top 100 Files</h1>
        <div class="cursor-pointer text-white pr-2" @click="toggleSel()" v-if="tempSelArr.length === 0">{{isManualSelect ? 'Cancel' : 'Select'}}</div>
        <div class=" text-error cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="removeConfirm=true" v-show="tempSelArr.length > 0">
          <span><i class="fas fa-trash-alt"></i></span>
        </div>
      </div>
    </div>
    <div class="flex justify-center" >
        <div class=" sm:w-full lg:w-10/12 xl:w-9/12 2xl:w-9/12">
            <div class="sm:grid  text-gray3 mb-4 card rounded-xl bg-white p-4 my-4" :class="isManualSelect ? 'justify-between grid-cols-2' : 'justify-end grid-cols-1'">
              <div v-if="isManualSelect" class="flex items-center justify-between">
                <div class="flex items-center">
                  <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck()" v-model="isSelectAll" >
                  <span class="pl-2 cursor-pointer" @click="checkboxCheck()">{{isSelectAll ? 'Deselect All' : 'Select All'}}</span>
                </div>
                <div v-if="isManualSelect && tempSelArr.length > 0" class=" text-primary cursor-pointer font-semibold" @click="clearSelection()">Clear Selection</div>
              </div>
              <div :class="mobileView ? '' : 'text-right flex justify-end'" >
                <div class="" v-if="tempSelArr.length === 0">Showing 1-{{ totalCount }} of {{ totalCount }}</div>
                <div class="" v-else>Selected {{tempSelArr.length}} of {{ totalCount }}</div>
              </div>
            </div>
            <div class=" text-gray3 mb-4 card rounded-xl bg-white p-4 my-4 overflow-y-auto overflow-x-hidden h-full" :style="`max-height:` + cardHeight + `px;`">
                <div class="flex gap-5" style="flex-wrap: wrap;">
                <div v-for="(attach, a) in attachmentList" :key="a" @click="showFullImage(attach, a)" class="card rounded-xl bg-white " :class="attach.isSelected ? 'border border-error' : 'border border-gray-100'">
                  <div class=" attachment-card cursor-pointer group" style="width: 167px; height: 95px">
                    <div v-if="attach.docType === 'image'" class="text-center">
                      <img style="height: 70px;width: 140px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                      <div v-if="a + 1 !== attachmentList.length" class="invisible  absolute -bottom-14 bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                        <img style="height: 70px;width: 140px;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                        <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                        <div v-if="a + 1 !== attachmentList.length" class="invisible  w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                        <div v-if="a + 1 !== attachmentList.length" class="invisible  w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                        <div v-if="a + 1 !== attachmentList.length" class="invisible  w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.isSelected" class="absolute  rounded-md text-black justify-center flex items-center heading-2 font-bold text-opacity-100" style="height: 88px;width: 140px;background: #fcfcfcc7;"><i class="fas fa-check-circle text-error"></i></div>
                    <!-- <div class="absolute bottom-2 opacity-80 text-white text-center heading-6 bg-gray4 pl-1" style="width: 130px;">{{attach.fileSize | getFileSize}}</div> -->
                  </div>
                  <div class="text-center heading-6">{{attach.fileSize | getFileSize}}</div>
                </div>
               </div>
            </div>
            <Pagination v-if="!hidePagination"
            :defaultPerPage="100"
            :totalDataCount="totalCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
    </div>
    <FullScreen v-if="openFullScreen" :object="selectedData" :hideDelete="false"></FullScreen>
    <div v-if="removeConfirm" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'No'" @buttonAction="removeConfirm = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Yes'" @buttonAction="deleteAttach(null)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import FullScreen from '@/View/plan/fullScreenAtt.vue'
import Pagination from '@/View/components/pagination.vue'
import MyJobApp from "@/api/MyJobApp.js";
export default {
  name: "customer",
  components: {
    FullScreen,
    Button,
    Pagination
  },
  data() {
    return {
      hidePagination: true,
      mobileView: false,
      requestType: '',
      removeConfirm: false,
      selectedData: null,
      openFullScreen: false,
      cardHeight: '',
      firstLoading: false,
      isSelectAll: false,
      tempSelArr: [],
      isManualSelect: false,
      resetPaginationValues: false,
      totalCount: 0,
      totalSize: 0,
      pathPrefix: '',
      attachmentList: [],
      activeUer: {},
      start: 0,
      length: 100,
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
    this.$root.$on('fullImageHandler', (response) => {
      if (response) {
        this.deleteAttach(this.selectedData)
      }
      this.openFullScreen = false
    })
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.getlisting()
  },
  methods: {
    toggleSel () {
      this.isManualSelect = !this.isManualSelect
      if (!this.isManualSelect) {
        this.isSelectAll = false
        this.tempSelArr = []
        this.attachmentList.forEach(element => {
          element.isSelected = false
        });
      }
    },
    clearSelection () {
      this.tempSelArr = []
      // this.isManualSelect = false
      this.isSelectAll = false
      this.attachmentList.forEach(element => {
        element.isSelected = false
      });
    },
    checkboxCheck () {
      this.tempSelArr = []
      this.isSelectAll = !this.isSelectAll
      if (this.isSelectAll) {
        this.attachmentList.forEach(element => {
          element.isSelected = true
          this.tempSelArr.push(element)
        });
      } else {
        this.attachmentList.forEach(element => {
          element.isSelected = false
        });
        this.tempSelArr = []
      }
    },
    showFullImage (data, index) {
      console.log('data', data)
      console.log('index', index)
      console.log('this.isManualSelect', this.isManualSelect)
      if (this.isManualSelect) {
        if (!this.attachmentList[index].isSelected) {
          this.attachmentList[index].isSelected = true
          this.tempSelArr.push(this.attachmentList[index])
        } else {
          this.attachmentList[index].isSelected = false
          let fIndex = this.tempSelArr.findIndex(x => x.fileVaultId === data.fileVaultId)
          if (fIndex >= 0) {
            this.tempSelArr.splice(fIndex, 1)
          }
        }
        let count = 0
        this.attachmentList.forEach(element => {
          if (element.isSelected) {
            count ++
          } else {
            count --
          }
        })
        if (count === this.tempSelArr.length) {
          this.isSelectAll = true
        } else {
          this.isSelectAll = false
        }
      } else {
        this.selectedData = data
        this.openFullScreen = true
        console.log('heheheeheheheheheeh')
      }
    },
    getQuoteDataFromPagination (data) {
      this.start = data.offset
      this.length = data.limit
      this.getlisting()
    },
    getlisting () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetAttachmentList(
        this.start,
        this.length,
        response => {
          this.totalCount = response.Data.count
          if (response.Data.count <= 100) {
            this.hidePagination = true
          } else [
            this.hidePagination = false
          ]
          this.pathPrefix = response.Data.pathPrefix
          let templist = response.Data.attachmentList !== null ? response.Data.attachmentList : [] 
          if (this.start >= 100) {
            this.requestType = 'other'
          } else {
            this.requestType = 'topLarge'
          }
          console.log('this.requestType', this.requestType)
          console.log('this.start', this.start)
          for (let index = 0; index < templist.length; index++) {
            let temp = templist[index].fileType.split('/')
            if (temp[0] === 'image' ) {
              templist[index].docType = 'image'
            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
              templist[index].docType = 'pdf'
            } else if (temp[0] === 'video' ) {
              templist[index].docType = 'video'
            }
            templist[index].isSelected = false
          }
          this.attachmentList = templist
          if (this.attachmentList.length === 0) {
            this.$router.push({name: 'SubscriptionStorage'})
          }
          this.resetPaginationValues = false
          setTimeout(() => {
            this.firstLoading = true
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }, 100);
          this.resizeWindowHandler()
          console.log('attachmentList', this.attachmentList)
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      console.log('this.hidePagination', this.hidePagination)
      if (this.hidePagination) {
        this.cardHeight = window.innerHeight - 240
      } else {
        this.cardHeight = window.innerHeight - 300
      }
      if (window.innerWidth < 642) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
      },
    deleteAttach (data) {
      this.removeConfirm = false
      let deletedArr = []
      let excludeDeleteAttachment = []
      if (data !== null) {
        deletedArr.push(data.fileVaultId)
      } else {
        if (!this.isSelectAll) {
          this.tempSelArr.forEach((a) => {
            deletedArr.push(a.fileVaultId)
          })
        }
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DeleteAttachmentWeb(
        deletedArr,
        excludeDeleteAttachment,
        this.isSelectAll,
        this.requestType,
        response => {
          this.getlisting()
          this.tempSelArr = []
          // this.isManualSelect = false
          this.isSelectAll = false
          this.attachmentList.forEach(element => {
            element.isSelected = false
          });
          this.isManualSelect = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
        },
        (err) => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
        }
      )

    },
  },
  beforeDestroy() {
    this.$root.$off("fullImageHandler");
  },
};
</script>
<style scoped>
.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.scroll-img-view{
  height: 104px;
}
.zIndex {
  z-index: 100 !important;
}
</style>